var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    },
    on: {
      "goPrev": _vm.goPrev
    }
  }), _c('div', {
    staticClass: "DAV-blockClass",
    class: {
      'DAV-blockClass--option-mode': this.$route.path === '/option'
    }
  }, [_c('div', {
    staticClass: "multiple-input-wrap"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.text,
      expression: "text"
    }],
    staticClass: "frmBlock__textField multiple-textarea",
    attrs: {
      "id": "textarea",
      "type": "text",
      "placeholder": "내용을 입력해주세요."
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.text = $event.target.value;
      }, _vm.changeInput]
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }