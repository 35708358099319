<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green" @goPrev="goPrev" />
    <div
      class="DAV-blockClass"
      :class="{
        'DAV-blockClass--option-mode': this.$route.path === '/option'
      }"
    >
      <div class="multiple-input-wrap">
        <textarea
          id="textarea"
          class="frmBlock__textField multiple-textarea"
          type="text"
          placeholder="내용을 입력해주세요."
          @input="changeInput"
          v-model="text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  data() {
    return {
      text: ""
    };
  },
  computed: {
    ...ModuleEditor.mapGetters(["getPopup"])
  },
  mounted() {
    console.log(this.getPopup);
    this.text = this.getPopup.value;
    this.$nextTick(() => {
      document.getElementById("textarea").style.height = "96px";
      document.getElementById("textarea").style.height = `${
        document.getElementById("textarea").scrollHeight
      }px`;
    });
  },
  methods: {
    // 이전 리스트로 이동
    goPrev() {
      this.$store.commit("ModuleEditor/setPopup", {
        page: null
      });
    },
    // textarea change Input
    changeInput(e) {
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option.componentOptionId
          : this.getPopup.option.id;

      this.$EventBus.$emit(
        `MultiTextUpdateText${id}`,
        e.target.value,
        this.getPopup.index
      );
      e.target.style.height = "96px";
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  },
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.multiple-input-wrap {
  background: #fff;
  padding: 24px 16px;

  .multiple-textarea {
    resize: none;
    padding: 12px 16px;
    overflow: hidden;
  }
}

/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-blockClass {
    &--option-mode {
      top: 57px;
    }
  }
  .DAV-workshop__heading {
    display: none !important;
    &--option-mode {
      display: block !important;
    }
  }
}
</style>
